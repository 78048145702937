import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], param: string, action: string, args?: any[], id?: number): any {
        switch (action) {
            case 'without' : return items.filter((item: any) => !args.includes(item[param]));
            case 'with' : return items.filter((item: any) => args.includes(item[param]));
            case 'has' : return items.filter((item: any) => item[param] === true);
            case 'not_has' : return items.filter((item: any) => !item[param]);
            case 'with_id' : return items.filter((item: any) => item[param] === id);
        }
    }
}
