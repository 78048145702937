import { Component, Inject, Renderer2, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CoreService } from './core/services/core.service';
import { LoaderService } from './theme/services/loader.service';
import { NgwWowService } from 'ngx-wow';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private renderer: Renderer2,
        private loaderService: LoaderService,
        private deviceDetectorService: DeviceDetectorService,
        private coreService: CoreService,
        private wowService: NgwWowService,
    ) {
        this.deviceDetectorInit();
    }

    ngAfterViewInit() {
        this.wowInit();
    }

    deviceDetectorInit() {

        if (this.coreService.isBrowser) {
            const deviceInfo = this.deviceDetectorService.getDeviceInfo();

            this.renderer.addClass(this.document.body, 'app-dd-browser-' + deviceInfo.browser.toLowerCase());
            this.renderer.addClass(this.document.body, 'app-dd-browser-version-' + deviceInfo.browser_version.toLowerCase().split('.')[0]);

            this.renderer.addClass(this.document.body, 'app-dd-os-' + deviceInfo.os.toLowerCase());
            this.renderer.addClass(this.document.body, 'app-dd-os-version-' + deviceInfo.os_version.toLowerCase().split('.')[0]);

            if (this.deviceDetectorService.isDesktop()) {
                this.renderer.addClass(this.document.body, 'app-dd-desktop');
            }

            if (this.deviceDetectorService.isTablet()) {
                this.renderer.addClass(this.document.body, 'app-dd-tablet');
            }

            if (this.deviceDetectorService.isMobile()) {
                this.renderer.addClass(this.document.body, 'app-dd-mobile');
            }
        }
    }

    wowInit() {

        if (this.coreService.isBrowser) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.wowService.init();
                }
            });
        }
    }
}
