import { Pipe, PipeTransform } from '@angular/core';
import { AccountModel } from '../../data/models/account.models';
import { AccountService } from '../../data/services/account.service';
import { CurrencyModel } from '../../data/models/currency';
import { CurrencyService } from '../services/currency.service';

@Pipe({
    name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {

    constructor(protected currencyService: CurrencyService) {}

    public transform(value: number, currency: CurrencyModel, small: boolean = false): string {
        return this.currencyService.formatCurrency(value, currency, small);
    }
}
