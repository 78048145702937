import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/internal/operators/tap';
import { JwtTokenService } from './jwt-token.service';
import { environment } from '../../../environments/environment';
import { AUTH_EVENT } from '../enums/auth-event';
import { UserModel } from '../../data/models/user.models';
import { map } from 'rxjs/operators';
import { CoreService } from './core.service';
import { LocalStorageService } from './local-storage.service';
import { switchMap } from 'rxjs/internal/operators';
import { Error } from 'tslint/lib/error';

// @ts-ignore
import * as detectEthereumProvider from '@metamask/detect-provider';

export interface AuthData {
    token: string;
    permissions: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    loginEvent: Subject<any>;
    authEvent: BehaviorSubject<any>;

    private readonly api = environment.api.baseUrl;

    private readonly defaultParentId: number = 250;

    constructor(
        private coreService: CoreService,
        private localStorageService: LocalStorageService,
        private jwtService: JwtTokenService,
        private router: Router,
        protected http: HttpClient,
    ) {
        this.loginEvent = new Subject();
        this.authEvent = new BehaviorSubject(this.isSigned ? AUTH_EVENT.LoggedIn : AUTH_EVENT.LoggedOut);
    }

    get isSigned(): boolean {
        return !!(this.jwtService.token || false);
    }

    get parentId(): string {
        const parentId = this.localStorageService.getItem('parent_id') || this.defaultParentId;
        return parentId.toString().padStart(6, '0');
    }

    get trafficHash(): string {
        return this.localStorageService.getItem('traffic_hash') || null;
    }

    get authStatus(): number {
        return this.authEvent.getValue();
    }

    getUser(): Observable<UserModel> {
        return this.http.get(`${this.api}/auth/user`).pipe(map((user: UserModel) => new UserModel(user)));
    }

    register(params): Observable<any> {
        return this.http.post(`${this.api}/auth/register`, params).pipe(
            tap(() => {
                this.clearParentId();
                this.authEvent.next(AUTH_EVENT.Verification);
                this.router.navigateByUrl('/auth').then();
            })
        );
    }

    metaMask(params): Observable<any> {
        return this.http.post(`${this.api}/auth/metamask`, params).pipe(
            tap((authData) => {
                this.setAuthData(authData.token, authData.permissions);
                this.authEvent.next(AUTH_EVENT.LoggedIn);
                this.router.navigateByUrl('/account').then();
            })
        );
    }

    verifyEmail(id: number, expires: string, signature: string) {
        this.http.get(`${this.api}/auth/email/verify/${id}?expires=${expires}&signature=${signature}`)
            .subscribe(
                (authData: AuthData) => {
                    if (authData.token && authData.permissions) {
                        this.setAuthData(authData.token, authData.permissions);
                        this.authEvent.next(AUTH_EVENT.LoginByVerify);
                        this.router.navigate(['/account']).then();
                    }
                },
                (err: HttpErrorResponse) => {
                    this.authEvent.next(AUTH_EVENT.Failed);
                    this.router.navigate(['/auth']).then();
                },
                () => {
                }
            );
    }

    resetPassword(params): Observable<any> {
        return this.http.post(`${this.api}/auth/password/forgot`, params).pipe(
            tap((authData: AuthData) => {
                this.authEvent.next(AUTH_EVENT.ResetProcessing);
                this.router.navigateByUrl('/auth').then();
            })
        );
    }

    // sendPassword(params): Observable<any> {
    //     if (this.coreService.isBrowser) {
    //         this.http.post(`${this.api}/auth/password/reset`, params)
    //             .subscribe(
    //                 () => {
    //                     this.authEvent.next(AUTH_EVENT.ResetSuccess);
    //                     this.router.navigateByUrl('/auth').then();
    //                 },
    //                 () => {
    //                 },
    //                 () => {
    //                 }
    //             );
    //     }
    // }

    sendPassword(params): Observable<any> {
        return this.http.post(`${this.api}/auth/password/reset`, params);
    }

    sendPasswordSuccess(): any {
        this.authEvent.next(AUTH_EVENT.ResetSuccess);
        return true;
    }

    login(params): Observable<any> {
        return this.http.post(`${this.api}/auth/login`, params).pipe(
            tap((authData: AuthData) => {
                this.setAuthData(authData.token, authData.permissions);
                this.authEvent.next(AUTH_EVENT.LoggedIn);
                this.router.navigateByUrl('/account').then();
            })
        );
    }

    loginByUser(id: number): Observable<any> {
        return this.http.post(`${this.api}/auth/login_by_user/${id}`, {}).pipe(
            tap((authData: AuthData) => {
                this.setAuthData(authData.token, authData.permissions);
                this.authEvent.next(AUTH_EVENT.LoggedIn);
                this.router.navigateByUrl('/account').then();
            })
        );
    }

    logout(params = true) {
        this.http.post(`${this.api}/auth/logout`, params)
            .subscribe(() => {
                this.clearAuthData();
                this.authEvent.next(AUTH_EVENT.LoggedOut);
                this.router.navigateByUrl('/auth/login').then();
            });
    }

    refreshToken() {
        return this.http.get<any>(`${this.api}/auth/refresh`).pipe(
            tap((authData: AuthData) => {
                this.setAuthData(authData.token, authData.permissions);
                this.loginEvent.next(AUTH_EVENT.LoggedIn);
            })
        );
    }

    setParentId(id?: string) {
        this.http.get(`${this.api}/auth/specify-parent-id${id ? (`?parent_id=${id}`) : ''}`)
            .subscribe(
                (response: any) => {
                    this.localStorageService.setItem('parent_id', response.parent_id);
                    // this.router.navigateByUrl(`${this.isSigned ? '/account' : '/auth/register'}`).then();
                },
                (err: HttpErrorResponse) => {
                },
                () => {
                }
            );
    }

    clearParentId() {
        this.localStorageService.removeItem('parent_id');
    }

    private setAuthData(token, permissions) {
        this.jwtService.setToken(token);
        this.jwtService.setPermissions(permissions);
    }

    clearAuthData(navigate?: boolean) {
        this.jwtService.clearToken();
        this.jwtService.clearPermissions();

        if (navigate) {
            this.router.navigateByUrl('/auth/login').then();
        }
    }

    testCookie(params: any = {}): Observable<any> {
        return this.http.post(`${this.api}/test`, params);
    }

    public getMetaMaskHash() {
        let ethereum: any;
        return from(detectEthereumProvider()).pipe(
            switchMap(async (provider) => {
                if (!provider) {
                    throw new Error('Please install MetaMask');
                }
                ethereum = provider;
                return await ethereum.request({method: 'eth_requestAccounts'});
            }),
            switchMap(async () => {
                    const message = 'Auth-CryptoChief.net:' + Date.now() ;
                    const signature = await ethereum.request({
                        method: 'personal_sign',
                        params: [
                            `0x${this.toHex(message)}`,
                            ethereum.selectedAddress,
                        ],
                    });

                    return btoa(`${signature}@${ethereum.selectedAddress}@${message}`);
                }
            ),
        );
    }

    private toHex(stringToConvert: string) {
        return stringToConvert
            .split('')
            .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
            .join('');
    }
}
