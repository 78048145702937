import { Pipe, PipeTransform } from '@angular/core';
import { PaywayModel } from '../../data/models/payway.models';

@Pipe({
    name: 'userWallet'
})
export class UserWalletPipe implements PipeTransform {

    constructor() {}

    public transform(collection: any[], payway: PaywayModel): string {
        const wallet = collection.find(itm => itm.payway_id === payway.id);
        return wallet ? wallet.address : '';
    }
}
