import { HttpClient } from '@angular/common/http';
import {
    Translation,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule,
    TranslocoService,
} from '@ngneat/transloco';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

export function i18nInitializer(router: Router, transloco: TranslocoService) {
    return () => {
        let lang = 'ru';
        transloco.setActiveLang(lang);
        return transloco.load(lang).toPromise();
    };
}

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
    private baseUrl = environment.api.baseUrl;
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`${this.baseUrl}/front/language/${lang}`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: i18nInitializer,
            multi: true,
            deps: [Router, TranslocoService],
        },
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['en', 'ru'],
                defaultLang: 'ru',
                fallbackLang: 'ru',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: false,
                prodMode: environment.production,
            }),
        },
        {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader},
    ],
})
export class TranslocoRootModule {
}
