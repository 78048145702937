export enum ACCOUNT_TYPE {
    PERSONAL = 1,
    PERSONAL_LOCKED = 2,
    BONUS = 3,
    BONUS_LOCKED = 4,
    DEPOSIT = 5,
    PARTNER = 6,
    PARTNER_LOCKED = 7,
    COIN = 8,
    ERC20 = 9,
    STAKING = 10,
    GRB = 11,
    GRB_LOCKED = 12,
    GR_TRC20 = 13,
    GR_LOCKED = 14,
    MIXER_INVEST = 15,
    MIXER_USER = 16,
    BONUS_REG_LOCKED = 17
}
