import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'metering'
})
export class MeteringPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number, variables: any) {
        let lastDigit = Math.floor(Math.floor(value)) % 100;
        lastDigit = (lastDigit >= 20) ? lastDigit % 10 : lastDigit;
        if (lastDigit === 0 ||
            lastDigit >= 5 && lastDigit <= 20) {
            return variables.zero + '';
        }
        else if (lastDigit === 1) {
            return variables.one + '';
        }
        else {
            return variables.two + '';
        }
    }
}
