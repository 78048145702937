import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number) {
        return value ? Number(value).toLocaleString('ru-RU') : '';
    }
}
