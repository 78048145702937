import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {

    private renderer: Renderer2;

    constructor(private router: Router, private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.showLoader();
            }
            if (event instanceof NavigationEnd) {
                this.hideLoader();
            }
        });
    }

    showLoader() {
        if (document.getElementById('website-loader')) {
            this.renderer.removeClass(document.body, 'loaded');
            this.renderer.setStyle(
                document.getElementById('website-loader'),
                'display',
                'flex'
            );
        }
    }

    hideLoader() {
        this.renderer.addClass(document.body, 'loaded');
        setTimeout(() => {
            if (document.getElementById('website-loader')) {
                this.renderer.setStyle(
                    document.getElementById('website-loader'),
                    'display',
                    'none'
                );
            }
        }, 500);
    }
}
