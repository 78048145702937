import { FindPaywayPipe } from './pipes/find-payway.pipe';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpCodeDirective } from './directives/http-code.directive';
import { SafePipe } from './pipes/safe.pipe';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { FindAccountPipe } from './pipes/find-account.pipe';
import { ToCoinsPipe } from './pipes/to-coins.pipe';
import { FromCoinsPipe } from './pipes/from-coins.pipe';
import { ToCurrencyPipe } from './pipes/to-currency.pipe';
import { FormatCurrencyPipe } from './pipes/formatCurrency.pipe';
import { FindCurrencyPipe } from './pipes/find-currency.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { UserWalletPipe } from './pipes/user-wallet.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { StriptagsPipe } from './pipes/striptags.pipe';
import { FindPipe } from './pipes/find.pipe';
import { NumberFormatPipe } from './pipes/numberFormat.pipe';
import { MeteringPipe } from './pipes/metering.pipe';

@NgModule({
    declarations: [
        SafePipe,
        HttpCodeDirective,
        FindAccountPipe,
        ToCoinsPipe,
        FromCoinsPipe,
        ToCurrencyPipe,
        FormatCurrencyPipe,
        FindCurrencyPipe,
        FilterPipe,
        UserWalletPipe,
        ReplacePipe,
        FindPaywayPipe,
        FindPipe,
        MeteringPipe,
        StriptagsPipe,
        NumberFormatPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    exports: [
        SafePipe,
        HttpCodeDirective,
        RecaptchaModule,
        RecaptchaFormsModule,
        FindAccountPipe,
        ToCoinsPipe,
        FromCoinsPipe,
        ToCurrencyPipe,
        FormatCurrencyPipe,
        FindCurrencyPipe,
        FilterPipe,
        UserWalletPipe,
        ReplacePipe,
        FindPaywayPipe,
        FindPipe,
        StriptagsPipe,
        NumberFormatPipe,
        MeteringPipe
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {siteKey: environment.recaptcha.key} as RecaptchaSettings,
        }
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [],
        } as ModuleWithProviders;
    }
}
