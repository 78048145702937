export class CurrencyModel {
    id: number;
    adjusted_rate_usd: number;
    rate_usd: number;
    bonus_rate_usd: number;
    multiplier: number;
    precision: number;
    code: string;
    symbol: string;
    is_autoupdate: boolean;
    is_fiat: boolean;
    created_at: string;
    updated_at: string;
    logo_image: any;

    constructor(item: {} | CurrencyModel) {
        Object.keys(item).forEach((key) => this[key] = item[key] || null);
    }

    get image(): string {
        return this.logo_image.hasOwnProperty('src') ? this.logo_image.src : '';
    }
}
