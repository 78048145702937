import { Injectable } from '@angular/core';
import { CurrencyModel } from '../../data/models/currency';

@Injectable({
    providedIn: 'root',
})

export class CurrencyService {

    constructor() {}

    get getUsd(): CurrencyModel {
        return new CurrencyModel({
            id: 1,
            rate_usd: 1,
            adjusted_rate_usd: 1,
            bonus_rate_usd: 0,
            multiplier: 100,
            precision: 2,
            is_fiat: true,
            code: 'usd',
            symbol: '$'
        });
    }

    toCoins(amount: number, currency: CurrencyModel): number {
        return Math.round(amount * currency.multiplier);
    }

    fromCoins(amount: number, currency: CurrencyModel): number {
        return +(amount / currency.multiplier).toFixed(currency.precision);
    }

    toCurrency(amount: number, currencyFrom: CurrencyModel, currencyTo: CurrencyModel, format?: boolean): number | string {
        const value = Math.round(amount * currencyTo.rate_usd / currencyFrom.rate_usd);
        return format ? this.formatCurrency(value, currencyTo) : value;
    }

    formatCurrency(amount: number, currency: CurrencyModel, small: boolean = false): string {
        let value = (amount / currency.multiplier).toFixed(currency.precision);

        if (small && currency.code === 'grom.erc20') {
            value = value + ' GR';

            return value;
        }

        if (amount <= 0) {
            value = currency.is_fiat ? `${currency.symbol} ${value}` : `${value} ${currency.symbol}`;
        } else {
            value = currency.is_fiat ? (currency.symbol + ' ').concat(value) : `${value} ${currency.symbol}`;
        }

        return value.trim();
    }
}

