import { Pipe, PipeTransform } from '@angular/core';
import { AccountModel } from '../../data/models/account.models';
import { AccountService } from '../../data/services/account.service';
import { CurrencyModel } from '../../data/models/currency';
import { CurrencyService } from '../services/currency.service';

@Pipe({
    name: 'findCurrency'
})
export class FindCurrencyPipe implements PipeTransform {

    constructor(protected currencyService: CurrencyService) {
    }

    public transform(data: CurrencyModel[], code: string): CurrencyModel {
        const currency = data.find(itm => itm.code === code);
        return currency ? currency : this.currencyService.getUsd;
    }
}
