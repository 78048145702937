import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { JwtTokenService } from '../services/jwt-token.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {

    constructor(private tokenService: JwtTokenService, private authService: AuthService) {
    }

    private addToken(httpRequest: HttpRequest<any>, token: string) {
        return httpRequest.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.tokenService.token && request.url.includes('/api/')) {
            request = this.addToken(request, this.tokenService.token);
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if (error.error.hasOwnProperty('message') && error.error.message.toLowerCase().includes('token')) {
                        this.authService.clearAuthData(true);
                    }
                    if (error.error.hasOwnProperty('error') && error.error.error.toLowerCase().includes('token')) {
                        this.authService.clearAuthData(true);
                    }
                    if (error.error.hasOwnProperty('message') && error.error.message === 'Unauthenticated.') {
                        this.authService.logout();
                    }
                }
                return throwError(error);
            })
        );
    }
}
