import { ACCOUNT_TYPE } from '../enums/account-type';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AccountModel } from '../../data/models/account.models';

@Pipe({
    name: 'findAccount',
})
@Injectable({
    providedIn: 'root',
})
export class FindAccountPipe implements PipeTransform {
    constructor() {}

    public transform(data: AccountModel[], type: string, paywayId: number): AccountModel {
        let account = null;
        switch (type) {
            case 'personal':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.PERSONAL && itm.payway_id === paywayId);
                break;
            case 'bonus':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.BONUS && itm.payway_id === paywayId);
                break;
            case 'bonus_locked':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.BONUS_LOCKED && itm.payway_id === paywayId);
                break;
            case 'bonus_reg_locked':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.BONUS_REG_LOCKED && itm.payway_id === paywayId);
                break;
            case 'partner':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.PARTNER && itm.payway_id === paywayId);
                break;
            case 'coin':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.COIN && itm.payway_id === paywayId);
                break;
            case 'erc20':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.ERC20 && itm.payway_id === paywayId);
                break;
            case 'staking':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.STAKING && itm.payway_id === paywayId);
                break;
            case 'grb':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.GRB && itm.payway_id === paywayId);
                break;
            case 'trc20':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.GR_TRC20 && itm.payway_id === paywayId);
                break;
            case 'grusd-trc20':
                account = data.find((itm) => itm.account_type_id === ACCOUNT_TYPE.GR_TRC20 && itm.payway_id === paywayId);
                break;
            default:
                break;
        }
        return account ? account : ({ balance: 0, erc20_withdrawal_amount: 0 } as AccountModel);
    }
}
