import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striptags'
})
export class StriptagsPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string) {
        return value ? String(value).replace(/<[^>]+>/gm, '') : '';
    }
}
