import { PaywayModel } from './../../data/models/payway.models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findPayway'
})
export class FindPaywayPipe implements PipeTransform {

    constructor() {
    }

    public transform(data: PaywayModel[], pwId: number): PaywayModel {
        const payway = data.find(itm => itm.id === pwId);
        return payway ? payway : null;
    }
}
