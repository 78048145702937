import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { CoreModule } from './core/core.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtTokenInterceptor } from './core/interceptors/jwt-token.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        // TransferHttpCacheModule,
        HttpClientModule,
        DeviceDetectorModule.forRoot(),
        TranslocoRootModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        NgSelectModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtTokenInterceptor,
            multi: true,
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: LangInterceptor,
        //     multi: true
        // }
    ],
})
export class AppModule {}
