export const environment = {
    production: false,
    api: {
        baseUrl: 'https://api-staging.cryptochief.net/api',
        fakeEnabled: true,
    },
    recaptcha: {
        key: '6Lfc_voUAAAAAAurxFRr69PkOpJjAsK37IAysx1N',
    }
};
