import { Pipe, PipeTransform } from '@angular/core';
import { AccountModel } from '../../data/models/account.models';
import { AccountService } from '../../data/services/account.service';
import { CurrencyModel } from '../../data/models/currency';
import { CurrencyService } from '../services/currency.service';

@Pipe({
    name: 'toCurrency'
})
export class ToCurrencyPipe implements PipeTransform {

    constructor(protected currencyService: CurrencyService) {}

    public transform(value: number, currencyFrom: CurrencyModel, currencyTo: CurrencyModel, format?: boolean): number | string {
        return this.currencyService.toCurrency(value, currencyFrom, currencyTo, format);
    }
}
