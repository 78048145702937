import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'find'
})
export class FindPipe implements PipeTransform {
    transform(items: any[], param: string, arg: string | number): any {
        return items.find((item: any) => item[param] === arg);
    }
}
